@tailwind base;
@tailwind components;
@tailwind utilities;

/* BODY */
html {
  @apply fixed w-full h-full overflow-hidden;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.app {
  @apply relative;
}

body,
#__next {
  @apply h-full max-w-full min-h-screen p-0 m-0 overflow-x-hidden text-white bg-purple-darker;
}
main {
  @apply w-full overflow-hidden;
}

/* TYPOGRAPHY */
p {
  font-family: "Geometria", sans-serif;
  @apply text-lg font-medium leading-8;
}

h1 {
  @apply text-3xl leading-[48px] text-center md:text-left md:text-5xl font-bold md:leading-[80px];
}

h3 {
  @apply text-2xl font-bold;
}

label > span {
  @apply block mb-1 text-sm font-semibold leading-4;
}
.tiny-label {
  @apply text-sm leading-6;
}

.btn {
  @apply text-base font-bold;
  font-family: "Geometria", sans-serif;
}
.btn.small {
  @apply h-10 text-xs;
}

/* INPUT */
.input-container {
  @apply relative flex flex-col w-full gap-3 font-sans;
}
.select-container {
  @apply w-[fit-content];
}
input {
  @apply w-full h-12 px-5 py-3 font-medium transition-all duration-300 border-2 rounded outline-none bg-purple-darkest border-purple;
}
input:focus {
  @apply bg-purple-light;
}

/* BUTTONS */
.btn {
  @apply inline-flex items-center justify-center w-full h-12 gap-2 px-4 py-3 font-bold;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem #0000000d;
}
.btn.round {
  @apply z-10 grid w-12 h-12 rounded-full bg-purple-darkest place-content-center md:h-[60px] md:w-[60px] md:text-2xl;
}
.btn.round.active {
  @apply bg-purple;
}
.btn.filled,
.btn-primary {
  @apply text-white;
  background-image: linear-gradient(120deg, #8e2de2 0%, #4a00e0 99%);
  background-size: 200% auto;
  transition: 0.5s;
  border: none !important;
}
.btn.filled:hover,
.btn-primary:hover {
  background-position: bottom right;
}
.btn.filled:disabled {
  @apply opacity-90;
  background-color: #2f273a;
  background-image: none;
}
.btn.outline {
  @apply border-2 outline-none text-purple border-purple disabled:bg-gray-darker disabled:border-gray-darker disabled:text-gray-light;
}
.btn.text {
  @apply text-purple;
}

.address-button {
  @apply absolute top-0 right-0 z-30 flex items-center justify-center w-full gap-8 py-8 md:pl-[300px];
}

.address-button > .relative {
  @apply max-w-[16.25rem];
}

.account-button__address {
  @apply relative grid h-12 px-5 py-4 rounded bg-purple-transparent place-content-center;
}

.account-button__tag {
  @apply px-3 py-1.5 text-sm bg-purple mx-auto font-bold rounded text-white grid place-content-center;
}

.account-button__tag-container {
  @apply absolute w-[140px] px-2 -left-2 top-10;
}

hr {
  @apply w-full mb-8 border-gray opacity-10;
}

/* COUNTDOWN */
.countdown {
  @apply flex items-center justify-center gap-3 mt-10 font-bold md:gap-5 md:text-5xl;
}
.countdown__card {
  @apply flex flex-col w-[3.75rem] h-[5.625rem] md:w-[7rem] md:h-[7rem] md:text-lg font-medium rounded;
}
.countdown__time {
  @apply grid flex-1 pb-2 text-xl font-bold bg-purple-darkest place-content-center md:text-4xl;
}
.countdown__measure {
  @apply p-1 text-center bg-purple;
}

/* CHECKBOX */
.lboard-checkbox-container {
  @apply flex items-center gap-2 text-sm cursor-pointer;
}
.lboard-checkbox-box {
  @apply flex items-center justify-center p-1 rounded-lg cursor-pointer w-7 h-7 bg-purple;
}
/* CHECKBOX GROUP */
.lboard-checkbox-group {
  @apply flex flex-col items-start w-full gap-3;
}

/* SLIDER */
.rc-slider-rail {
  background-color: #4a00e0 !important;
  height: 8px !important;
}
.rc-slider-track {
  background-color: #8e2de2 !important;
  height: 8px !important;
}
.rc-slider-handle {
  background-color: #8e2de2 !important;
  border: 1px solid #8e2de2 !important;
  height: 20px !important;
  width: 20px !important;
}
.rc-slider-handle-dragging {
  box-shadow: none !important;
}
.rc-slider-handle:hover {
  @apply bg-purple-darkest;
}

/* CONTAINER OVERRIDE */
.container {
  @apply md:ml-[8rem] p-5 md:py-10 max-w-[80rem] md:px-[120px] flex flex-col gap-5 z-20 relative;
}

.row {
  @apply flex w-full gap-3;
}

.col {
  @apply flex flex-col w-full gap-2;
}

ul > li > span {
  @apply flex-1;
}

/* SIDEBAR */
.sidebar {
  @apply absolute bottom-0 left-0 flex justify-between w-full md:flex-col px-5 py-4 md:py-10 bg-purple-darkest z-40 items-center h-[72px] md:h-screen md:w-[200px];
}
.sidebar > ul {
  @apply flex items-start gap-4 md:gap-10 md:flex-col;
}
.sidebar > ul > li,
.sidebar > ul > li > a {
  @apply relative flex items-center gap-4 transition-colors duration-300;
}
.sidebar > ul > li:hover,
.sidebar > ul > li > a:hover {
  @apply cursor-pointer text-purple;
}
.sidebar__logo-desktop {
  @apply mb-10;
}
.notification-count {
  @apply absolute px-2 py-1 text-xs rounded-full bg-purple -right-2 -top-2 md:right-auto md:left-4;
}
.sidebar__social {
  @apply hidden gap-5 mt-auto mb-10 md:flex;
}

.sidebar__logo {
  @apply flex items-center justify-center gap-5;
}
.sidebar__logo > button:hover {
  @apply transition-colors duration-300 text-purple;
}
.sidebar__logo > button {
  @apply flex items-center gap-3;
}
.sidebar__logo > button > img {
  @apply flex items-center justify-center w-10 h-10 border rounded-full bg-purple-lighter border-purple;
}
.sidebar__logo > button > span {
  @apply hidden text-sm md:block;
}

/* CARDS */
.draggable-card {
  @apply absolute bottom-0 md:top-0 md:left-[12.25rem] flex flex-col z-[20] items-center w-full gap-5 p-5 pt-2 rounded-t bg-purple-transparent md:pt-8 max-w-[330px];
}
.draggable-card__expand {
  @apply p-4 rounded-t mb-[-20px] md:hidden;
}
.menu-container {
  @apply absolute left-0 flex items-center justify-center w-full z-[31] md:items-start md:bottom-0 md:top-0 bottom-5 md:h-[21rem] md:w-[330px];
}

.buy-token-card {
  @apply flex flex-col items-center p-5 mt-32 rounded bg-purple-darkest max-w-[24rem] mx-auto;
}

.buy-token-card > h2 {
  @apply mb-4 text-2xl font-bold text-center text-white;
}

.notifications-card-modal {
  @apply z-50 relative w-full h-full md:w-[330px] md:ml-[200px];
}
.notifications-card {
  @apply absolute top-0 left-0 flex flex-col gap-5 bg-purple-transparent w-full md:w-[400px] pt-20 overflow-auto;
  height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
}
.notifications-card > h2 {
  @apply mb-5 text-3xl font-bold text-center;
}
.notifications-card__close {
  @apply absolute top-5 right-5;
}
.notifications-not-found {
  @apply flex flex-col items-center gap-5;
}
.notifications-not-found > svg {
  @apply max-h-[240px] max-w-[240px];
}
.notifications-not-found > h1 {
  @apply text-3xl font-bold text-center;
}
.notifications-not-found > p {
  @apply text-center;
}

.notification {
  @apply flex h-[120px] justify-between py-5 relative pr-5 border-t-2;
}
.notification-header {
  @apply flex items-center gap-4 pl-20;
}
.notification-animation {
  @apply absolute top-0 left-0;
}

.notification-header__info {
  @apply flex flex-col gap-1;
}
.notification-header__info > span {
  @apply flex items-center gap-2;
}
.notification-actions {
  @apply flex flex-col items-end justify-between;
}
.notification-actions__extra {
  @apply flex gap-1;
}

.nft-card {
  @apply p-8 w-[350px] rounded-lg bg-purple-darker;
}
.nft-card__header {
  @apply flex items-center gap-5;
}
.nft-card__header > img {
  @apply border-purple border rounded w-[6.75rem] h-full;
}
.nft-card__header-text {
  @apply flex flex-col gap-3;
}
.nft-card__content {
  @apply flex flex-col gap-4 my-8;
}
.nft-card__content > li {
  @apply flex items-center gap-2;
}
.nft-card__actions {
  @apply flex items-center gap-5;
}

.nft-card__actions-extra {
  @apply flex items-center gap-3;
}

.filters-card {
  @apply relative flex flex-col gap-5 p-5 px-10 rounded mt-80 bg-purple-darkest;
}
.filters-modal {
  @apply absolute top-0 left-0;
}
.filters-button {
  @apply fixed w-full px-24 mx-auto left-0 bottom-[72px] md:hidden;
}

.image-card-upload > .image-card__links {
  @apply flex -mt-2;
}
.image-card {
  @apply relative border rounded border-purple;
}
.image-card > img {
  @apply rounded-t;
}
.image-card.tiny {
  @apply w-[5rem] h-[5rem];
}
.image-card__links {
  @apply flex items-center justify-center w-full h-12 gap-3 border rounded-b bg-purple-darkest border-purple;
}

.filter-card {
  @apply p-5 rounded md:max-w-[440px] bg-purple-darkest flex gap-4 items-end;
}

.place-card {
  @apply relative rounded bg-purple-darkest max-w-[25rem];
}
.place-card__title {
  @apply absolute top-[-1.25rem] right-6 border-2 w-[12.5rem] z-10 text-center rounded border-purple font-bold bg-purple-darkest py-2;
}
.place-card__header {
  @apply flex gap-5;
}
.place-card__actions {
  @apply flex flex-col items-center flex-1 gap-5 mt-12 mr-6;
}
.place-card__actions > .relative > button {
  @apply flex w-[7.5rem];
}
.place-card > ul {
  @apply pt-2 pb-5 pl-5;
}

.buy-place-card {
  @apply mt-12 md:mt-32;
}
.after-buy-card {
  @apply flex flex-col items-center justify-center mt-12 md:mt-32;
}
.offer-place-card {
  @apply mt-12;
}
.random-mint-card {
  @apply relative mt-[260px] md:mt-[340px] md:overflow-y-auto max-h-full;
}
.random-mint-card__details {
  @apply flex flex-col gap-2;
}
.random-mint-card__details > p {
  @apply text-xs;
}
.random-mint-card__details > h4 {
  @apply font-bold;
}
.loading-card {
  @apply relative h-[256px] mt-[200px] bg-purple-transparent rounded p-5 max-w-[330px] mx-auto grid place-content-center;
}
.loading-card > h2 {
  @apply absolute z-10 text-xl bottom-6 left-32;
}
.buy-place-card,
.offer-place-card,
.random-mint-card,
.share-tile-card,
.after-buy-card {
  @apply flex flex-col items-start gap-5 p-5 rounded bg-purple-darkest z-40 relative max-w-[20rem] md:max-w-[33rem] mx-auto;
}
.random-mint-card {
  @apply left-0 md:left-[12.25rem] md:bg-purple-transparent;
}
.buy-place-card > h2,
.offer-place-card > h2,
.random-mint-card > h2 {
  @apply text-2xl font-bold;
}
.buy-place-card > hr {
  @apply my-2 border-t-2 opacity-50 border-purple;
}
.buy-place-card > ul {
  @apply flex flex-col w-full gap-3;
}
.buy-place-card > ul > li {
  @apply flex justify-between;
}
.buy-place-card__info,
.offer-place-card__info,
.random-mint-card__info {
  @apply flex items-center gap-2 p-2 text-sm border rounded text-gray-light border-purple;
}
.buy-place-card__actions {
  @apply flex w-full gap-2;
}
.random-mint-card__actions {
  @apply flex items-end w-full gap-2;
}
.random-mint-card__actions > .input-container {
  @apply w-1/2;
}
.buy-place-card__close,
.offer-place-card__close {
  @apply absolute top-[-1.25rem] right-[-1rem] p-1 border-2 border-purple font-bold bg-purple-darkest rounded-full;
}
.offer-place-card > form {
  @apply flex flex-col gap-5;
}
.offer-place-card__actions {
  @apply flex w-full;
}
.offer-place-card__actions > span {
  @apply flex items-center px-5 font-bold border-2 rounded-l border-purple text-purple;
}

.share-tile-card {
  @apply p-10 top-60;
}
.share-tile-card > h2 {
  @apply text-xl font-extrabold;
}
.share-tile-card__socials {
  @apply flex items-center justify-center gap-5 mt-3;
}

.toast {
  @apply flex items-center justify-between w-full md:w-[335px] rounded border-2 p-5 z-50 font-semibold font-sans relative;
}
.toast-header {
  @apply flex items-center gap-3;
}
.toast-animation {
  @apply absolute top-0 left-0;
}
.info {
  @apply border-[#1D45AD] bg-[#04020F];
}
.success {
  @apply border-[#1DAD2B] bg-[#020F03];
}
.error {
  @apply border-[#AD1D1D] bg-[#0F0202];
}

.loading-spinner {
  @apply inline mr-2 w-6 h-6 animate-spin fill-[gray];
}

.badge {
  @apply relative z-20 flex items-center gap-2 px-4 py-2 text-sm rounded-full w-[fit-content] text-purple bg-purple-lighter;
}

.avatar-uploader {
  @apply relative flex flex-col items-center gap-3;
}
.avatar-uploader > .badge,
.image-card > .badge {
  @apply absolute top-3 left-3;
}
.avatar-uploader__overlay {
  @apply absolute top-0 left-0 opacity-80;
}

.avatar-uploader > button > figure {
  @apply border-2 rounded border-purple w-[200px] h-[200px];
}
.avatar-uploader__error {
  @apply text-sm text-[#f76262];
}

.upload-button {
  @apply absolute w-full px-4 py-2 bg-purple bg-opacity-20 bottom-4;
}
.upload-button > .lboard-checkbox-container {
  @apply mb-3;
}

.avatar-close {
  @apply absolute top-[-12px] right-[-12px] p-2 py-2 rounded-full bg-purple-light;
}

.tiny-place-card {
  @apply relative flex w-full gap-3 py-3 pr-3 rounded bg-purple-darkest;
}
.tiny-place-card > img {
  @apply border-2 rounded border-purple;
}
.tiny-place-card__price {
  @apply absolute top-[-1.25rem] right-6 p-2 font-bold bg-purple rounded;
}
.tiny-place-card__info {
  @apply flex flex-col gap-2;
}
.tiny-place-card__info > h2 {
  @apply text-xl font-bold;
}

/* MODALS */
.lboard-modal {
  @apply absolute top-0 left-0 z-40 w-screen h-screen;
}
.lboard-modal__backdrop {
  @apply absolute top-0 left-0 w-screen h-screen transition-colors duration-300 bg-purple bg-opacity-20;
}

/* THEME PICKER */
.theme-picker {
  @apply flex flex-col gap-3 font-bold;
}

.theme-picker__label {
  @apply flex items-center gap-3;
}

.theme-picker__themes {
  @apply flex gap-3;
}

.theme-picker__theme {
  @apply w-8 h-8 rounded-full cursor-pointer;
}

/* COPY */
.copy-container {
  @apply relative w-full h-full p-3;
}
.copy-container__icon {
  @apply absolute p-1 transition-colors duration-300 border rounded cursor-pointer right-2 top-2 border-purple hover:bg-purple-dark;
}

/* TABLE */
table {
  @apply w-full overflow-x-auto;
}
table.large > tbody > tr > td {
  @apply min-w-[6rem] md:min-w-[8rem];
}

table a {
  @apply flex items-center gap-3;
}

th {
  @apply p-3 text-xs uppercase border-2 text-purple bg-purple-darkest border-purple md:text-base;
}

tr > td {
  @apply p-3 text-xs text-center text-white border-2 bg-purple-light border-purple md:text-base;
}

/* SELECT */

.select-item {
  @apply flex items-center w-full justify-between px-3.5 py-2.5 cursor-pointer hover:bg-purple-light;
}
.select-item.is-selected {
  @apply bg-purple-light;
}

/* SCROLLBAR */
*::-webkit-scrollbar {
  @apply w-0 cursor-pointer md:w-3;
}
*::-webkit-scrollbar-thumb {
  @apply rounded bg-purple;
}
*::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-90;
}

/* TEXT SELECTION */
::-moz-selection {
  @apply text-white bg-purple;
}
::selection {
  @apply text-white bg-purple;
}

/* PAGINATION */
.pagination > ul {
  @apply flex justify-center gap-3;
}
.pagination > ul > li {
  @apply p-3 bg-purple-darkest;
}
.pagination > ul > li.active {
  @apply p-3 font-bold bg-purple-light text-purple;
}

/* MAP CONTROLS */

.lboard-map-controls {
  @apply absolute flex flex-col gap-3 right-5 bottom-28;
}
.lboard-map-control {
  @apply p-2 rounded-full bg-purple-transparent;
}

/* OVERRIDES */
/* GEOCODER */
.mapboxgl-ctrl-geocoder {
  @apply relative z-20;
}
.suggestions-wrapper {
  @apply absolute w-full bg-purple-darker;
}
.mapboxgl-ctrl-geocoder--input {
  padding: 1.5rem 1.25rem !important;
}
.mapboxgl-ctrl-geocoder--suggestion {
  @apply px-4 py-2;
}
.mapboxgl-ctrl-geocoder--icon,
.mapboxgl-ctrl-geocoder--pin-right {
  @apply hidden;
}

.mapbox-gl-geocoder--no-results {
  @apply p-3;
}

/* POPUP */
.mapboxgl-popup,
.mapboxgl-popup-content {
  background-color: transparent !important;
  max-width: 432px !important;
}
.lboard-popup {
  @apply relative flex flex-col gap-3 p-5 pt-8 bg-purple-transparent;
}
.lboard-popup > h3 {
  @apply flex flex-col items-start gap-2;
}
.lboard-popup .badge {
  @apply text-xs;
}

.lboard-popup__price {
  @apply absolute gap-1 top-[-1.25rem] left-3 bg-purple-transparent border-purple border flex items-center justify-center rounded w-52 text-base font-bold p-2;
}
.mapboxgl-popup-tip {
  @apply hidden;
}
.mapboxgl-popup-content__container {
  @apply flex flex-col w-[304px];
  padding: 1.25rem 1.25rem 1.25rem !important;
  background-color: rgba(20, 7, 59, 0.5) !important;
}
.popup-details {
  @apply flex flex-col gap-2 mt-3 mb-6 text-base font-medium;
}
.popup-details > li {
  @apply flex items-center gap-2;
}
.popup-details > li > span {
  @apply max-w-[224px];
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  z-index: 0 !important;
}

.mapboxgl-popup-close-button {
  @apply hidden;
}

/* PAGES */
.page-404 {
  padding-top: 140px !important;
}
.not-found__container {
  @apply flex flex-col items-center justify-center w-full gap-4 md:gap-20 md:flex-row;
}
.page-404__actions {
  @apply flex gap-5;
}

.map-page-container {
  @apply relative overflow-hidden;
}

.not-found {
  @apply flex flex-col items-center h-full px-5 text-center md:text-left md:items-start;
}
.not-found > h1 {
  @apply mt-8 mb-3 text-3xl font-bold md:text-6xl;
}
.not-found > p {
  @apply text-base text-center md:mb-5 md:text-left md:text-lg;
}
.dracula {
  @apply w-[330px] h-[269px] md:h-[500px] md:w-[816px];
}

.leaderboard,
.faucet > h1 {
  @apply mb-2 md:mb-10;
}
.leaderboard__header {
  @apply flex items-center justify-between w-full;
}
.leaderboard__header > button {
  @apply w-[120px] md:w-[200px] h-[48px];
}

.faucet > .relative > button {
  @apply max-w-[16rem];
}

.nfts-container {
  @apply flex flex-col px-5  items-center min-h-screen gap-5 py-7 md:px-[100px];
}
.nfts-container > .filters {
  @apply flex items-center justify-center gap-5 px-4 my-5 md:pl-20 md:w-1/2;
}
.nfts-container__list {
  @apply flex flex-wrap justify-center w-full col-auto gap-7;
}

.tabs-component {
  @apply flex flex-col gap-6;
}

.tab-links {
  @apply flex flex-row gap-3 rounded bg-purple-darkest;
}

.tab {
  @apply relative h-[2.625rem] w-24 flex items-center justify-center;
}

.tab-background {
  @apply absolute top-0 left-0 w-full h-full mr-2 rounded bg-purple;
}

.tab a {
  @apply relative flex items-center h-full font-semibold;
}

.details {
  @apply flex flex-col items-center md:items-start gap-8 overflow-auto md:overflow-visible md:flex-row md:w-[45rem] bg-purple-darkest p-5 md:p-8;
}

@media (max-width: 768px) {
  .profile {
    @apply overflow-auto;
    max-height: calc(100vh - 72px);
  }
  .place-details {
    @apply overflow-auto;
    max-height: calc(100vh - 72px);
  }
}

.details__form {
  @apply flex flex-col items-start w-full gap-5;
}
.details__content {
  @apply flex flex-col items-start w-full;
}
.details__content > h1 {
  @apply text-3xl font-bold;
}
.details__content > ul,
.place-card > ul {
  @apply flex flex-col gap-4 mt-3;
}
.details__content > ul > li,
.place-card > ul > li {
  @apply flex items-center gap-2;
}
.details__content > form {
  @apply flex flex-col gap-5 mt-8;
}

.lands {
  @apply flex flex-col items-center gap-5;
}

.lands-grid__not-found,
.lands-grid {
  @apply flex flex-col gap-10 mt-10 mb-20 md:grid md:grid-cols-2;
}
.lands-grid__not-found {
  @apply md:grid-cols-1;
}

.offers,
.trading {
  @apply flex flex-col flex-1 gap-5;
}

.offers__header,
.trading__header {
  @apply flex flex-row items-center gap-3 mb-10;
}
.offers__header > h1,
.trading__header > h1 {
  @apply text-3xl font-bold;
}

.offers__header > .relative,
.trading__header > .relative {
  @apply ml-auto;
}

.place-details {
  @apply md:p-0 md:pl-40;
}
.place-details__content {
  @apply flex flex-col gap-20;
}
.place-details__content > .row {
  @apply flex flex-row gap-20 max-w-[1440px];
}

.profile {
  @apply flex flex-col md:items-center w-full gap-10 md:pl-[100px] md:mt-20 p-5 overflow-auto;
}
.details__content > form {
  @apply w-full md:w-[25rem];
}

.profile,
.place-details,
.leaderboard,
.faucet,
.page-404 {
  @apply pt-28 pb-[5rem];
}

.faucet {
  @apply flex flex-col items-center gap-5 md:items-start;
}

.page-404__content {
  @apply px-5 flex flex-col gap-3 w-full md:max-w-[37.5rem] items-center justify-center md:items-start;
}
.page-404__content > .relative {
  @apply max-w-xs;
}

.page-404 {
  @apply flex flex-col items-center justify-center gap-5 py-0 md:flex-row;
}

/* DAPP UI OVERRIDES */
/* .modal-card, */
.unlock-page_card,
.dapp-core-component__dappModalStyles__dappModalBody {
  @apply p-5 text-white border-0 border-none rounded;
  background: #14073b !important;
}

.dapp-core-component__dappModalStyles__dappModalContent {
  @apply border-0 border-none;
  border: none !important;
}

.dapp-core-component__main__card {
  @apply p-5 text-white border-0 border-none rounded;
  background: #14073b !important;
}

.dapp-core-component__main__btn-light {
  @apply text-white border-0 border-none;
  background: #060213 !important;
  color: #fff !important;
  border: none !important;
}

.dapp-core-component__dappModalStyles__dappModalHeader {
  @apply text-white bg-purple-light bg-purple-darker;
  background: #060213 !important;
  border: none !important;
}

.dapp-core-component__dappModalStyles__dappModalContent {
  background: none !important;
}

.unlock-page_card {
  @apply min-w-[18.75rem];
}
.unlock-page_title {
  @apply text-3xl font-bold;
}

.dapp-core-component__dappModalStyles__dappModalContent {
  @apply text-black;
  /* background: bg-purple-darkest !important; */
}

.__dappModalContent {
  @apply bg-purple-darker;
}

.dapp-core-component__main__btn-primary {
  @apply bg-purple-darkest;
  background-image: linear-gradient(120deg, #8e2de2 0%, #4a00e0 99%);
  background-size: 200% auto;
  transition: 0.5s;
  border: none !important;
}

.modal-content > .dapp-core-ui-component > .modal-card_wrapper {
  @apply flex flex-col gap-5 p-5 rounded bg-purple-darkest;
}
.unlock-page_cardBody {
  @apply flex flex-col items-center justify-center w-full;
}
.dapp-core-ui-component,
.btn-primary {
  @apply w-full mb-2;
}
.modal-container {
  @apply grid h-full px-5 place-content-center;
}
.modal-content {
  @apply grid place-content-center;
}
.card-title {
  @apply w-full;
}
.card-title > div {
  @apply flex items-center justify-between w-full;
}
.unlock-page_cardContainer {
  @apply grid place-content-center;
}
.modal-card {
  @apply py-0;
}
.wallect-connect-login_title {
  @apply my-4;
}
.unlock-page_wrapper {
  @apply h-full pt-32 md:pt-40;
}
.unlock-page_description {
  @apply mb-5;
}
.wallect-connect-login,
.ledger-login {
  @apply absolute z-[1000] top-40;
}
.card-body {
  @apply flex flex-col items-center justify-center w-full gap-4;
}
.justify-content-center {
  @apply mb-5;
}
.wallect-connect-login_leadText {
  @apply hidden;
}
.wallect-connect-login_mobileLoginButton {
  display: grid !important;
}
.wallect-connect-login_mobileLoginButton > svg {
  @apply hidden;
}
.modal-container {
  @apply absolute top-0 left-0 w-screen h-screen z-[1000];
}
.modal-backdrop.show {
  @apply absolute top-0 left-0 w-screen h-screen transition-colors duration-300 bg-purple bg-opacity-40 z-[100];
}
.modal-dialog {
  @apply mt-40;
}
.page-state_wrapper {
  @apply flex flex-col items-center justify-center w-full gap-5;
}

.full-screen {
  @apply mt-20 md:mt-40;
}

.rewards {
  @apply flex flex-col justify-center gap-5;
}
.home__title > p {
  @apply text-center;
}
.rewards-container {
  @apply flex flex-col gap-5 justify-center items-center max-w-[280px] mx-auto;
}
.rewards > .home__title > h1 {
  text-align: center !important;
}
.rewards-list {
  @apply flex flex-col justify-center w-full gap-3;
}
.rewards-list > li {
  @apply flex items-center gap-5;
}

.badges-container {
  @apply flex flex-col items-center justify-center w-full gap-5 mt-10 text-center;
}
.badges-container > h2 {
  @apply text-3xl text-center;
}
.badges-grid {
  @apply grid grid-cols-3 gap-5;
}
.badges-grid > .badge,
.badges-grid > .badge > img {
  @apply w-20 h-20 rounded-full;
}

.buy-tokens-container {
  @apply grid w-full h-screen pb-20 md:pb-40 place-content-center;
}

.no-zoom {
  touch-action: pan-x pan-y;
  height: 100%;
}

.lnk {
  @apply underline text-purple;
}

.select-button {
  @apply flex bg-purple-darkest z-[100] px-5 py-3 rounded border-2 border-purple focus:bg-purple-light items-center justify-between w-full;
}

.select-option {
  @apply flex items-center justify-between px-5 py-4 cursor-pointer bg-purple-darkest hover:bg-purple-light;
}
.select-group-label {
  @apply px-5 py-3 border-t-2 first:border-t-0 bg-purple-light border-purple;
}
button .select-option {
  @apply w-full;
}

.tooltip {
  @apply p-2 text-sm rounded-md bg-purple-darker;
}

.disabled {
  @apply text-gray;
}

.buy-land > svg {
  @apply flex items-center justify-center;
}

/* hero */

.hero-container {
  @apply z-10 flex flex-col items-center justify-between w-full md:flex-row text-center md:max-h-[23.25rem] gap-12;
}

.reverse {
  @apply md:flex-row-reverse;
}
.hero-container__text {
  @apply flex flex-col gap-5 md:gap-12 max-w-[38rem] mt-6 md:mt-6 md:text-left text-center;
}

/* Referral */

.referrals-wrapper {
  @apply flex flex-col items-center justify-center w-full gap-5 py-10 bg-purple-darkest;
}
