@font-face {
	font-family: "Geometria";
	src: url("https://app.landboard.io/geometria/Geometria-Bold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Geometria";
	src: url("https://app.landboard.io/geometria/Geometria-ExtraBold.woff2") format("woff2");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Geometria";
	src: url("https://app.landboard.io/geometria/Geometria.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Geometria";
	src: url("https://app.landboard.io/geometria/Geometria-Medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Geometria";
	src: url("https://app.landboard.io/geometria/Geometria-Heavy.woff2") format("woff2");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Geometria";
	src: url("https://app.landboard.io/geometria/Geometria-Heavy.woff2") format("woff2");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
